<template>
    <v-app>
    <div>
      <h1>Página Inicial</h1>
      <Padrao @ler-campos="lerCampos" />
      <p>Valor do campo 1: {{ valorCampo1 }}</p>
      <p>Valor do campo 2: {{ valorCampo2 }}</p>
    </div>
    </v-app> 
  </template>
  
  <script>
  import Padrao from './components/Padrao.vue';
  
  export default {
    components: {
      Padrao,
    },
    data() {
      return {
        valorCampo1: '',
        valorCampo2: '',
      };
    },
    methods: {
      lerCampos(valores) {
        this.valorCampo1 = valores.campo1;
        this.valorCampo2 = valores.campo2;
      },
    },
  };
  </script>
  