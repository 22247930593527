<template>
    <div>
      <input v-model="campo1" />
      <input v-model="campo2" />
      <button @click="lerCampos">Ler Campos</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        campo1: 'teste',
        campo2: 'teste',
      };
    },
    methods: {
      lerCampos() {
        this.$emit('ler-campos', { campo1: this.campo1, campo2: this.campo2 });
      },
    },
  };
  </script>
  